
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
// import EditCampana from "./edit-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import CardBarChart from "../../../components/Muse/Cards/CardBarChart.vue";

// // Line chart for "Sales Overview" card.
import CardLineChart from "../../../components/Muse/Cards/CardLineChart.vue";

// // Counter Widgets
import WidgetCounter from "../../../components/Muse/Widgets/WidgetCounter.vue";

// // "Projects" table component.
import CardProjectTable from "../../../components/Muse/Cards/CardProjectTable.vue";

// // Order History card component.
import CardOrderHistory from "../../../components/Muse/Cards/CardOrderHistory.vue";

// // Information card 1.
import CardInfo from "../../../components/Muse/Cards/CardInfo.vue";

// // Information card 2.
import CardInfo2 from "../../../components/Muse/Cards/CardInfo2.vue";
import Campana from "../../../store/entities/OZONE/campana";

// "Projects" table list of columns and their properties.

class PageTransferenciaRequest extends PageRequest {
  keyword: string;
  fechaInicio: Date;
  fechaFin: Date;
  campana: Campana;
  enviada: Boolean;
  emitida: Boolean;
  pendiente: Boolean;
  rechazada: Boolean;
  ids: Array<number>;
}

@Component({
  components: {
    //EditCampana,
    PeopleFind,
    CardBarChart,
    CardLineChart,
    WidgetCounter,
    CardProjectTable,
    CardOrderHistory,
    CardInfo,
    CardInfo2,
  },
})
export default class CampanasDashBoard extends AbpBase {

columns = [
  {
    title: 'Marca',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    fixed: 'left',
    filters: [
      {
        text: 'Joe',
        value: 'Joe',
      },
      {
        text: 'John',
        value: 'John',
      },
    ],
    onFilter: (value, record) => record.name.indexOf(value) === 0,
  },
  {
    title: 'Other',
    children: [
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        width: 200,
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Address',
        children: [
          {
            title: 'Street',
            dataIndex: 'street',
            key: 'street',
            width: 200,
          },
          {
            title: 'Block',
            children: [
              {
                title: 'Building',
                dataIndex: 'building',
                key: 'building',
                width: 100,
              },
              {
                title: 'Door No.',
                dataIndex: 'number',
                key: 'number',
                width: 100,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Company',
    children: [
      {
        title: 'Company Address',
        dataIndex: 'companyAddress',
        key: 'companyAddress',
        width: 200,
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
      },
    ],
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    width: 80,
    fixed: 'right',
  },
];
   get data(){
       const data = [];
        for (let i = 0; i < 100; i++) {
        data.push({
            key: i,
            name: 'John Brown',
            age: i + 1,
            street: 'Lake Park',
            building: 'C',
            number: 2035,
            companyAddress: 'Lake Street 42',
            companyName: 'SoftLake Co',
            gender: 'M',
        });
        }
        return data;
   }








  //filters
  pagerequest: PageTransferenciaRequest = new PageTransferenciaRequest();
  creationTime: Date[] = [];
  authorsHeaderBtns: String = "all";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  clienteNombre: string = "";

  get tableData() {
    var data =
      this.$store.state.transferencia.dashboard.informacionCashback.map(
        (cash, index) => ({
          key: index,
          camapana: cash.camapana,
          budget: "€" + cash.presupuesto,
          client: cash.cliente,
          participaciones: cash.participacionesValidas,
          transferencias: cash.transferencias,
          enviadas: cash.enviadas,
          emitidas: cash.emitidas,
          rechazadas: cash.rechazadas,
          pendientes: cash.pendientes,
          presupuesto: cash.presupuesto,
          presupuestoUsado:
            cash.enviadas.importe +
            cash.emitidas.importe +
            cash.pendientes.importe,
            min: cash.min,
            max: cash.max,
            avg: cash.avg
        })
      );
    console.log(data);

    return data;
  }

  campana: Campana = null;

  searchDefinition: {}[] = [
    {
      fieldName: "Busqueda",
      fieldType: "text",
      fieldBind: "keyword",
      value: "",
    },
    {
      fieldName: "Fecha Inicio",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: "",
    },
    {
      fieldName: "Fecha Fin",
      fieldType: "date",
      fieldBind: "fechaFin",
      value: "",
    },
    {
      fieldName: "Campaña",
      fieldType: "custom",
      custom: "select-campana",
      fieldBind: "campana",
      value: this.campana,
    },
    {
      fieldName: "Pendiente",
      fieldType: "checkbox",
      fieldBind: "pendiente",
      value: false,
    },
    {
      fieldName: "Enviada",
      fieldType: "checkbox",
      fieldBind: "enviada",
      value: false,
    },
    {
      fieldName: "Emitida",
      fieldType: "checkbox",
      fieldBind: "emitida",
      value: false,
    },
    {
      fieldName: "Rechazada",
      fieldType: "checkbox",
      fieldBind: "rechazada",
      value: false,
    },
  ];


  tableColumns = [
    {
      title: "Campaña",
      scopedSlots: { customRender: "func" },
    },
    {
      title: "Presupuesto",
      scopedSlots: { customRender: "budget" },
    },
    {
      title: "Participaciones | Transferencias",
      scopedSlots: { customRender: "partrans" },
    },
    {
      title: "Pendientes",
      scopedSlots: { customRender: "completion" },
      dataIndex: "pendientes",
    },

    {
      title: "Enviadas",
      scopedSlots: { customRender: "completion" },
      dataIndex: "enviadas",
    },

    {
      title: "Emitidas",
      scopedSlots: { customRender: "completion" },
      dataIndex: "emitidas",
    },

    {
      title: "Rechazadas",
      scopedSlots: { customRender: "completion" },
      dataIndex: "rechazadas",
    },
        {
      title: "Min. | Promedio | Max.",
      scopedSlots: { customRender: "avg" },
    },
  ];
  pagination: any = {};

  get stats() {
    return [
      {
        title: "Total",
        valuee: this.$store.state.transferencia.dashboard.total,
        prefix: "€",
        // suffix: "+30%",
        icon: `<i class="fa fa-euro-sign" style="color:white"></i>`,
      },
      {
        title: "Pendientes",
        valuee: this.$store.state.transferencia.dashboard.pendientes.importe,
        prefix: "€",
        // suffix: "+20%",
        icon: `<i class="fa fa-ellipsis-h" style="color:white"></i>`,
      },
      {
        title: "Enviadas",
        valuee: this.$store.state.transferencia.dashboard.enviadas.importe,
        prefix: "€",
        status: "danger",
        // suffix: "-20%",
        icon: `<i class="fa fa-paper-plane" style="color:white"></i>`,
      },
      {
        title: "Emitidas",
        valuee: this.$store.state.transferencia.dashboard.emitidas.importe,
        prefix: "€",
        // suffix: "+10%",
        icon: `<i class="fa fa-piggy-bank" style="color:white"></i>`,
        precision: 2
      },
    ];
  }

  get statr() {
    return {
      title: "Rechazadas",
      valuee: this.$store.state.transferencia.dashboard.rechazadas.importe,
      prefix: "€",
      suffix: this.$store.state.transferencia.dashboard.rechazadas.cantidad,
      icon: `<i class="fa fa-ban" style="color:red"></i>`,
      status: "danger",
      precision: 2
    };
  }

  get list() {
    return this.$store.state.transferencia.list;
  }
  get dashboard() {
    return this.$store.state.transferencia.dashboard;
  }

  get loading() {
    return this.$store.state.transferencia.loading;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });
    await this.getpage();
    this.findModalShow = false;
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit("transferencia/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("transferencia/setPageSize", pagesize);
    this.getpage();
  }

  async getpage() {
    // this.pagerequest.maxResultCount = this.pageSize;
    // this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    // await this.$store.dispatch({
    //   type: "transferencia/getAll",
    //   data: this.pagerequest,
    // });

    // await this.$store.dispatch({
    //   type: "transferencia/getDashboard",
    //   data: this.pagerequest,
    // });

    // const pagination = { ...this.pagination };
    // pagination.total = this.$store.state.transferencia.totalCount;
    // pagination.pageSize = this.$store.state.transferencia.pageSize;
    // pagination.current = this.$store.state.transferencia.currentPage;
    // this.pagination = pagination;
  }

  get pageSize() {
    return this.$store.state.transferencia.pageSize;
  }

  get totalCount() {
    return this.$store.state.transferencia.totalCount;
  }

  get currentPage() {
    return this.$store.state.transferencia.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("transferencia/setCurrentPage", page);
    this.getpage();
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("transferencia/setCurrentPage", pagination.current);
    this.getpage();

    await this.getpage();
  };

  async getCliente(clienteId) {
    ;
    var cliente = "";
    var result = this.$store.dispatch({
      type: "transferencia/get",
      id: clienteId,
    });
    return this.$store.state.cliente.editCliente.cliente.nombre;
  }

  columns2 = [
    {
      title: this.L("Participacion"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("IBAN"),
      dataIndex: "iban",
    },
    {
      title: this.L("Importe"),
      dataIndex: "importe",
      scopedSlots: { customRender: "money" },
    },
    {
      title: this.L("Estado"),
      dataIndex: "estadoTransferencia",
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Fecha Envio"),
      dataIndex: "fechaEnvio",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Fecha Devolución"),
      dataIndex: "fechaDevolucion",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Observaciones"),
      dataIndex: "observaciones",
    },
    {
      title: this.L("Creada"),
      dataIndex: "fechaCreacion",
      scopedSlots: { customRender: "date" },
    },
  ];

  async created() {
    this.getpage();
  }
}
